import { env } from '@core/env';
import { Locale } from '@model/local.types';

const addYearLocal = (year: number, locale: Locale) => {
  if (locale === 'th') {
    return year + 543;
  }
  return year;
};

export default function getYearString(year: number, locale: Locale = 'th'): string {
  const startYear = env.startYear;
  if (year === startYear) {
    return `${addYearLocal(startYear - 2, locale)} - ${addYearLocal(startYear, locale)}`;
  }
  return addYearLocal(year, locale).toString();
}
