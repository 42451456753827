import CurrentUserReport from '@model/current-user-report';
import { ReportStatus } from '@model/enum/report-status.enum';
import { UserRole } from '@model/enum/user-role.enum';
import { Profile } from '@model/profile';
import { ConfirmResponse } from '@model/response/confirm.response';
import React, { createContext, useReducer } from 'react';

type FromValidType = { valid: boolean; key: string };
type FormSavingType = { isSaving: boolean; key: string };
type Action =
  | { type: 'SET_CONFIRM'; payload: ConfirmResponse | null }
  | { type: 'SET_FORM_VALID'; payload: FromValidType }
  | { type: 'SET_SAVING'; payload: FormSavingType }
  | { type: 'SET_REPORT_STATUS'; payload: ReportStatus }
  | { type: 'SET_USER_ROLE'; payload: string }
  | { type: 'SET_CURRENT_USER_REPORTS'; payload: CurrentUserReport[] }
  | { type: 'SET_CURRENT_USER_REPORT'; payload: CurrentUserReport | null }
  | { type: 'SET_PROFILE'; payload: Profile | null }
  | { type: 'SET_CURRENT_NODE'; payload: string | null }
  | { type: 'SET_AS_OF_YEAR'; payload: number | null };
type Dispatch = (action: Action) => void;
type State = {
  confirmReport: ConfirmResponse | null;
  enableForm: boolean;
  formValid: Record<string, boolean>;
  formSaving: Record<string, boolean>;
  enableConfirm: boolean;
  isFormValid: boolean;
  isSaving: boolean;
  readonly: boolean;
  reportStatus: ReportStatus | null;
  userRole: string | null;
  asOfYear: number | null;
  profile: Profile | null;
  currentNode: string | null;
  currentUserReport: CurrentUserReport | null;
  currentUserReports: CurrentUserReport[];
};

const ReportStatusStateContext = createContext<State | undefined>(undefined);
const ReportStatusDispatchContext = createContext<Dispatch | undefined>(undefined);

const isFormValid = (formValid: Record<string, boolean>): boolean => {
  if (Object.keys(formValid).length === 0) {
    return false;
  }
  return Object.values(formValid).every((v) => v === true);
};

const isFormSaving = (formSaving: Record<string, boolean>): boolean => {
  if (Object.keys(formSaving).length === 0) {
    return false;
  }
  return Object.values(formSaving).some((v) => v === true);
};

const otherUserIsInNode = (
  currentUserReports: CurrentUserReport[],
  currentNode: string | null,
  profile: Profile | null,
  currentUserReport: CurrentUserReport | null
): boolean => {
  if (profile === null) {
    return true;
  }
  if (currentUserReports.length === 0) {
    return false;
  }
  // console.log(currentUserReport, profile);
  if (
    currentUserReport &&
    currentUserReport.userProfile &&
    currentUserReport.userProfile.id !== null &&
    profile &&
    profile.id !== currentUserReport.userProfile.id
  ) {
    return true;
  }
  if (
    !currentUserReports.some(
      (c) => c.nodeName === currentNode && c.userProfile !== null && c.userProfile.id !== null && profile.id !== c.userProfile.id
    )
  ) {
    return false;
  }
  return true;
};

const calculateState = (state: State): State => {
  let enableForm = false;
  let enableConfirm = false;
  const readonly = state.userRole === UserRole.ESG_APPROVER;
  const isSaving = isFormSaving(state.formSaving);
  const valid = isFormValid(state.formValid);
  const isOtherUserIsInNode = otherUserIsInNode(state.currentUserReports, state.currentNode, state.profile, state.currentUserReport);

  if (!isOtherUserIsInNode && state.currentNode === 'sendToApprover' && !readonly) {
    enableForm = true;
  } else if (!isOtherUserIsInNode && state.currentNode === 'approveReport' && readonly) {
    enableForm = true;
  } else if (!isOtherUserIsInNode && !readonly && state.reportStatus !== ReportStatus.WAIT_FOR_APPROVE) {
    if (state.confirmReport && !state.confirmReport.confirm) {
      enableForm = true;
    }
    if ((valid || (!valid && state.confirmReport && state.confirmReport.confirm)) && isSaving === false) {
      enableConfirm = true;
    }
  }

  return { ...state, enableForm, isFormValid: valid, enableConfirm, readonly, isSaving };
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_CONFIRM': {
      return calculateState({ ...state, confirmReport: action.payload });
    }
    case 'SET_FORM_VALID': {
      const payload = action.payload;
      const formValid = { ...state.formValid, [payload.key]: payload.valid };
      return calculateState({ ...state, formValid });
    }
    case 'SET_SAVING': {
      const payload = action.payload;
      const formSaving = { ...state.formSaving, [payload.key]: payload.isSaving };
      return calculateState({ ...state, formSaving });
    }
    case 'SET_AS_OF_YEAR': {
      return calculateState({ ...state, asOfYear: action.payload });
    }
    case 'SET_REPORT_STATUS': {
      return calculateState({ ...state, reportStatus: action.payload });
    }
    case 'SET_USER_ROLE': {
      return calculateState({ ...state, userRole: action.payload });
    }
    case 'SET_CURRENT_NODE': {
      const currentUserReports = state.currentUserReports;
      let currentUserReport = state.currentUserReport;
      const currentNode = action.payload;
      if (currentUserReports.length > 0 && (currentUserReport === null || currentUserReport.nodeName !== currentNode)) {
        currentUserReport = currentUserReports.find((c) => c.nodeName === currentNode) ?? null;
      }
      return calculateState({ ...state, currentNode, currentUserReport });
    }
    case 'SET_CURRENT_USER_REPORTS': {
      const currentUserReports = action.payload ?? [];
      return calculateState({ ...state, currentUserReports });
    }
    case 'SET_CURRENT_USER_REPORT': {
      return calculateState({ ...state, currentUserReport: action.payload });
    }
    case 'SET_PROFILE': {
      return calculateState({ ...state, profile: action.payload });
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface ReportStatusProviderProps {
  children?: React.ReactNode;
  profile?: Profile | null;
  currentUserReports?: CurrentUserReport[];
}
const ReportStatusProvider = ({ children, currentUserReports, profile }: ReportStatusProviderProps): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, {
    confirmReport: null,
    enableForm: false,
    enableConfirm: false,
    formValid: {},
    formSaving: {},
    isFormValid: false,
    isSaving: false,
    asOfYear: null,
    readonly: false,
    reportStatus: null,
    userRole: null,
    currentNode: null,
    profile: profile ?? null,
    currentUserReport: null,
    currentUserReports: currentUserReports ?? [],
  });

  return (
    <ReportStatusStateContext.Provider value={state}>
      <ReportStatusDispatchContext.Provider value={dispatch}>{children}</ReportStatusDispatchContext.Provider>
    </ReportStatusStateContext.Provider>
  );
};

const useReportStatusState = (): State => {
  const context = React.useContext(ReportStatusStateContext);
  if (context === undefined) {
    throw new Error('useReportStatusState must be used within a ReportStatusProvider');
  }
  return context;
};
const useReportStatusDispatch = (): Dispatch => {
  const context = React.useContext(ReportStatusDispatchContext);
  if (context === undefined) {
    throw new Error('useReportStatusDispatch must be used within a ReportStatusProvider');
  }
  return context;
};

export { ReportStatusProvider, useReportStatusState, useReportStatusDispatch };
