import getChildrenOnDisplayName from '@helpers/utils/get-children-on-display-name';
import React from 'react';
import Label from './ComponentLabel';
import Children from './ComponentChildren';
import classNames from 'classnames';
import { Icon, Tooltip, TooltipPlacement } from '@mis/sushi-tailwind-react';

type LabelAlign = 'center' | 'start' | 'baseline';

interface ComponentWithLabelProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
  labelAlign?: LabelAlign;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipClassNames?: string;
  ['data-testid']?: string;
}

interface ComponentWithLabelInterface extends React.ForwardRefExoticComponent<ComponentWithLabelProps> {
  Label: typeof Label;
  Children: typeof Children;
}

const excludeChildrenOnDisplayName = (children: React.ReactNode, displayName: string[]): React.ReactNode[] | null | undefined => {
  return React.Children.map(children, (child) => {
    return (child as AnyValue).type && displayName.includes((child as AnyValue).type.displayName) ? null : child;
  });
};

const ComponentWithLabel = React.forwardRef<HTMLDivElement, ComponentWithLabelProps>(
  ({ children, label, labelAlign = 'center', tooltip, tooltipPlacement = 'topLeft', ...props }, forwardRef): JSX.Element => {
    const labelComp = getChildrenOnDisplayName(children, 'ComponentLabel');
    const childrenComp = getChildrenOnDisplayName(children, 'ComponentChildren');
    const childrenExclude = excludeChildrenOnDisplayName(children, ['ComponentLabel', 'ComponentChildren']);
    const testid = props['data-testid'] || 'component-with-label';
    const className = classNames('flex flex-col gap-3 items-start md:flex-row', {
      'md:items-center': labelAlign === 'center',
      'md:items-start': labelAlign === 'start',
      'md:items-baseline': labelAlign === 'baseline',
    });
    return (
      <div className={className} ref={forwardRef} data-testid={testid}>
        {Array.isArray(labelComp) && labelComp.length > 0 ? (
          labelComp
        ) : (
          <div className="md:w-2/5 md:text-right" data-testid={`${testid}-label`}>
            <div className="inline-flex">
              <div className="whitespace-pre-line">{label}</div>
              {tooltip && (
                <div
                  style={{ paddingTop: '2px', paddingLeft: '0.35rem' }}
                  className={classNames('cursor-default', props.tooltipClassNames ? props.tooltipClassNames : null)}
                >
                  <Tooltip placement={tooltipPlacement} width={230} title={tooltip}>
                    <Icon name="help_outline" />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        )}
        {Array.isArray(childrenComp) && childrenComp.length > 0 ? (
          childrenComp
        ) : (
          <div className="w-full md:w-3/5" data-testid={`${testid}-children`}>
            {childrenExclude}
          </div>
        )}
      </div>
    );
  }
) as ComponentWithLabelInterface;
ComponentWithLabel.displayName = 'ComponentWithLabel';
ComponentWithLabel.Label = Label;
ComponentWithLabel.Children = Children;

export default ComponentWithLabel;
