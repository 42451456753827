import { Observable, from } from 'rxjs';
import axios from '@core/axios';
import { map } from 'rxjs/operators';
import { Locale } from '@model/local.types';
import { AxiosResponse } from 'axios';
import {
  ReportEnvironmentType,
  ReportGovernancePolicyType,
  ReportGovernanceSustainabilityPolicyType,
  ReportSocialType,
} from '@model/report-node';

export class DownloadService {
  public static downloadDocument(
    reportDetailId: number | string,
    type: ReportEnvironmentType | ReportSocialType | ReportGovernancePolicyType | ReportGovernanceSustainabilityPolicyType,
    generateName: string
  ): Observable<ArrayBuffer> {
    const url = `/download/${type}/${reportDetailId}?name=${generateName}`;
    return from(axios.get<ArrayBuffer>(url, { responseType: 'blob' })).pipe(
      map((data): ArrayBuffer => {
        return data.data;
      })
    );
  }

  public static preview(year: number, language: Locale, part: string, type: 'PDF' | 'XLS'): Observable<AxiosResponse<Blob>> {
    const url = `/download/${year}/preview?language=${language}&part=${part}&type=${type}`;
    return from(axios.get<Blob>(url, { responseType: 'blob' }));
  }

  public static downloadDocumentWithName(name: string): Observable<AxiosResponse<Blob>> {
    const url = `/download/document/${name}`;
    return from(axios.get<Blob>(url, { responseType: 'blob' }));
  }
}
